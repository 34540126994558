<template>
  <GlPageWrap
    class="users-page__wrapper"
    hide-title-on-mobile
    title="Users Manager"
  >
    <template #actions>
      <div class="m-pa-2 m-fixed-bottom-wrapper">
        <button
          class="gl-button gl-button--dark gl-button--padder m-fullwidth"
          @click="handleCreate"
        >
          Create new user
        </button>
      </div>
    </template>
    <div class="table-wrap">
      <div class="pa-3 users-list__filters-wrap">
        <gl-search-box
          v-model="email"
          class="users-list__search"
          dark-clear
          grey
          :height="'35px'"
          placeholder="Search by user email"
          @clear="filterUsers"
          @search="filterUsers"
        />
      </div>
      <o-table
        class="table__overflow-auto users-list"
        :class="{ 'o-table-has-pagination': users.length && totalPages > 1 }"
        :data="users"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        striped
      >
        <o-table-column
          v-slot="props"
          field="email"
          label="Email"
          width="450"
        >
          <div
            class="link-text"
            @click="goMailto(props.row.email)"
          >
            {{ props.row.email }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="companyName"
          label="Company name"
          width="300"
        >
          <div
            v-if="props.row.companyName"
            v-tooltip.top="props.row.companyName"
            class="ellipsis"
            style="max-width: 170px"
          >
            {{ props.row.companyName || '-' }}
          </div>
          <div v-else>
            --
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="email"
          label="Created By"
          :visible="!isSales"
          width="450"
        >
          <div
            :class="[{'link': props.row.createdByUser && props.row.createdByUser.email}]"
            @click="goMailto(props.row.createdByUser && props.row.createdByUser.email)"
          >
            {{ props.row.createdByUser && props.row.createdByUser.email ? props.row.createdByUser.email : '-' }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="role"
          label="Role"
          width="300"
        >
          {{ capitalizeFirstLetter(getLabelRole(props.row.role)) }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="activeTo"
          label="Expiration date"
          width="300"
        >
          {{ props.row.activeTo ? formatDate(props.row.activeTo, 'dd.MM.yyyy') : '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="requestsMade"
          label="Total Requests Used"
          width="380"
        >
          {{ Number.isInteger(props.row.requestsMade) ? toComaSeparate(props.row.requestsMade) : '--' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="requestedLeft"
          label="Remaining Requests"
          width="320"
        >
          <div class="tagging__value">
            <div v-if="!props.row.requestsTotal">
              <span class="users-list__unlimited-mark">∞</span>
            </div>
            <div
              v-else
              class="fs-12"
            >
              <span :class="[{ 'warning-text': isWithinPercentage(Math.max(props.row.requestsLeft, 0), props.row.requestsTotal) }]">
                {{ toComaSeparate(props.row.requestsLeft) }}
              </span> /
              <span>{{ toComaSeparate(props.row.requestsTotal) }}</span>
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="status"
          label="status"
          width="300"
        >
          <div>
            <gl-active
              v-model="props.row.disabled"
              :label="!props.row.disabled ? 'Active' : 'Disabled'"
              @input="handleActivateUser($event, props.row._id)"
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          class="gl-user-dropdown__list-item"
          field="actions"
          label=""
          width="100"
        >
          <div
            v-if="props.row.role !== 'External_System'"
            class="flex align-center justify-content-end"
          >
            <gl-icon
              class="mr-3 pointer"
              :height="16"
              name="more-new"
              :width="16"
              @click="ctxOpenHandler(props.row, $event)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div class="empty-users-data flex column align-center">
            <gl-icon
              class="mb-3"
              name="users"
            />
            {{ email ? 'No user with such email address was found' : 'No users here yet' }}
          </div>
        </template>
      </o-table>
      <o-pagination
        v-if="users.length && totalPages > 1"
        class="users-pagination m-mb-3"
        :current.sync="currentPage"
        order="centered"
        :per-page="perPage"
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
    <ContextMenu
      ref="ctx"
      v-model="showContextMenu"
      :data="ctxProfileData"
      :position="contextMenuPosition"
      @delete="userDelete"
      @edit="updateUser"
      @reset-password="handleResetPassword"
      @view="goToView"
    />
    <createUserModal
      v-if="createUser"
      v-model="createUser"
      :active-to="selectUser.activeTo || ''"
      :company-name="selectUser.companyName"
      :email="selectUser.email"
      :is-create="isCreate"
      :requests-made="selectUser.requestsMade || null"
      :requests-total="selectUser.requestsTotal || null"
      :role="selectUser.role"
      @add="setInvite"
      @close="createUser = false"
      @update="update(selectUser._id, $event)"
    />
    <resetPasswordModal
      v-model="resetPasswordModal"
      :email="selectUser.email"
      @close="resetPasswordModal = false"
      @submit="reset"
    />
    <deleteUserModal
      v-model="deleteModal"
      :data="selectUser"
      @close="deleteModal = false"
      @submit="remove"
    />
  </GlPageWrap>
</template>

<script>
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import moment from "moment";
// Vuex
import {mapActions, mapState, mapGetters} from 'vuex';
// Components
import GlIcon from '@/components/gl-icon'
import GlActive from "@/components/gl-active";
import GlMenuItem from '@/components/gl-menu-item'
import GlPageWrap from "@/components/layout/gl-page-wrap";
import createUserModal from "@/pages/users/modals/createUserModal";
import deleteUserModal from "@/pages/users/modals/deleteUserModal";
import resetPasswordModal from "@/pages/users/modals/resetPasswordModal";
import { getLabelRole, isWithinPercentage } from '@/utils/profile'
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import GlSearchBox from '@/components/gl-search-box.vue'
import { goMailto } from '@/utils/go-to-route'
import ContextMenu from '@/pages/users/components/ContextMenu.vue'

export default {
  components: {
    ContextMenu,
    GlSearchBox,
    GlIcon,
    GlActive,
    GlMenuItem,
    GlPageWrap,
    deleteUserModal,
    createUserModal,
    resetPasswordModal,
  },
  data() {
    return {
      showContextMenu: false,
      contextMenuPosition: { x: 0, y: 0 },
      ctxProfileData: null,
      isCreate: true,
      createUser: false,
      resetPasswordModal: false,
      deleteModal: false,
      selectUser: {},
      loading: false,
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      total: 1,
      users: [],
      email: ''
    }
  },
  computed: {
    ...mapState('user', ['id']),
    ...mapGetters('user', ['isSales']),
  },
  async created() {
    await this.getUsers()
  },
  methods: {
    getLabelRole,
    goMailto,
    capitalizeFirstLetter,
    isWithinPercentage,
    moment,
    ...mapActions({
      logout: 'user/logout',
      editUser: 'users/editUser',
      deleteUser: 'users/deleteUser',
      getUsersList: 'users/getUsersList',
      resetPassword: 'users/resetPassword',
      setActiveUser: 'users/setActiveUser',
      sendInviteUser: 'users/sendInviteUser',
      getUser: 'users/getUser',
    }),
    formatDate,
    toComaSeparate,
    goToView(data) {
      this.$router.push({ name: 'view-user-profile', params: { id: data._id } })
    },
    ctxOpenHandler(val, e) {
      const { x, y } = e
      this.contextMenuPosition = { x, y }

      this.showContextMenu = false
      this.ctxProfileData = val || null
      this.showContextMenu = true
    },
    filterUsers(search) {
      this.currentPage = 1
      this.email = search
      this.getUsers()
    },
    handleCreate() {
      this.createUser = true
      this.isCreate = true
      this.selectUser = {}
    },
    userDelete(user) {
      this.deleteModal = true
      this.selectUser = user
    },
    updateUser(user) {
      this.isCreate = false
      this.selectUser = user
      this.createUser = true
      this.showContextMenu = false
    },
    async getUsers() {
      this.loading = true
      const { data: { items, totalItems } } = await this.getUsersList({ email: this.email, count: this.perPage, skip: (this.currentPage - 1) * this.perPage})
      this.users = items.map(user => ({
        ...user,
        totalScoreRequests: user.requestsHistory ? user.requestsHistory.addressScore + user.requestsHistory.txScore : 0
      }))
      this.total = totalItems
      this.totalPages = Math.ceil(totalItems / this.perPage)
      this.loading = false
    },
    handleActivateUser(val, id) {
      const data = {
        userId: id,
        disabled: val
      }
      this.setActiveUser(data).then(({ success }) => {
        if (success) {
          this.getUsers()
        } else {
          this.$toasted.global.error({ message: `${data.message}` })
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({ message: `${data.data.message}` })
      })
    },
    reset(email) {
      if (email) {
        this.resetPassword(email).then(() => {
          this.resetPasswordModal = false
          this.$toasted.global.success({ message: `${email} password was successfully reseted` })
          this.selectUser = {}
        })
      }
    },
    handleResetPassword(data) {
      this.selectUser = data
      this.resetPasswordModal = !this.resetPasswordModal
    },
    pageChange(event) {
      this.currentPage = event
      this.getUsers()
    },
    setInvite(user) {
      this.sendInviteUser(user).then(({ success }) => {
        if (success) {
          this.createUser = false;
          this.$toasted.global.success({ message: `The invite was successfully sent to ${user.email}` })
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({ message: `${data.message}` })
      });
    },
    async update(id, data) {
      await this.editUser({id, body: {
          ...data,
          activeTo: data.activeTo || 0
        }}).then(() => {
        this.selectUser = {}
        this.createUser = false
      })
      await this.getUsers()
    },
    async remove(id) {
      if (id) {
        await this.deleteUser(id).then(() => {
          this.deleteModal = false
          this.$toasted.global.success({ message: 'The user was successfuly deleted' })
          if (this.id === id) {
            this.logout();
            window.location.assign('/');
          }
        })
        await this.getUsers()
      }
    }
  }
}
</script>

<style scoped>
.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}
</style>

<style>
.users-list {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.users-list .o-table .o-table__td  {
  border: none !important;
}

.users-list__search {
  width: 50%;
}

.users-list__filters-wrap {
  border-bottom: 1px solid var(--cotton-grey-f-5);
}

.users-list__unlimited-mark {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--space-cadet);
  vertical-align: middle;
}

@media (max-width: 767px) {
  .users-list__search {
    width: 100%;
  }

  .users-page__wrapper {
    margin-bottom: 60px;
  }
  .users-page__wrapper .o-pag--mobile .o-pag__previous, .users-page__wrapper .o-pag--mobile .o-pag__next {
    flex-grow: unset;
  }
}
</style>
